
<template>
  <BackofficeBase :loader_prop="loader_prop">
    <CourierCompanyMenu :courier_company_id_prop="CourierCompanyMenu" />
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
import CourierCompanyMenu from "./CourierCompanyMenu"

export default {
  name: 'BackofficeCourierCompany',
  components: {
    BackofficeBase,
    CourierCompanyMenu
  },
  data() {
    return {
      loader_prop : true,
      view_data : [],
      CourierCompanyMenu : 0
    }

  },
  async mounted() {
    console.log("this.$route.query.id:" + this.$route.query.id)
    this.id = this.$route.query.id
    console.log("this.id:" + this.id)
    this.CourierCompanyMenu = this.$route.query.id
    console.log("this.CourierCompanyMenu:" + this.CourierCompanyMenu)
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/get_courier_company", {"id": this.id})
    console.log(view_data.data.courier_company_data)
    this.view_data = view_data.data.courier_company_data
    this.loader_prop = false
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

